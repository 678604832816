import { useInject } from '@actr/di';
import { useNavigation } from '@actr/hooks';
import { LOGIN_STATUS } from '@actr/specs';
import { AuthStore } from '@actr/stores';
import { AccountRoutes, SiteLoader } from '@actr/widgets';
import { observer } from 'mobx-react-lite';
import React from 'react';

const withoutAuth = (
  WrappedComponent: React.FC<any>
): React.FC<any> => {
  const AuthCheck: React.FC<any> = observer(props => {
    const auth = useInject(AuthStore);

    const { navigate } = useNavigation();

    if (auth.status === LOGIN_STATUS.LOGGEDIN) {
      navigate(AccountRoutes.ORDERS, {});
      return null;
    }

    if ([LOGIN_STATUS.DEFAULT, LOGIN_STATUS.NOTLOGGEDIN].includes(auth.status)) {
      return (
        <WrappedComponent {...props} />
      );
    }

    return (
      <SiteLoader />
    );
  });

  return AuthCheck;
};

export default withoutAuth;
